import React from 'react';
function FreeIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9393 17.0607L7.7525 9.87383C2.93461 15.1962 0 22.2554 0 30C0 46.5685 13.4315 60 30 60C37.7446 60 44.8038 57.0654 50.1262 52.2475L42.9393 45.0607L45.0607 42.9393L52.2475 50.1262C57.0654 44.8038 60 37.7446 60 30C60 13.4315 46.5685 0 30 0C22.2554 0 15.1962 2.93461 9.87383 7.75251L17.0607 14.9393L14.9393 17.0607ZM31.5 11.5523V7H28.5V11.5383C26.4603 11.6476 24.1085 12.0266 22.0959 13.1193C20.8041 13.8207 19.6338 14.8258 18.7947 16.244C17.9561 17.6612 17.5 19.4035 17.5 21.5C17.5 23.0594 17.9306 24.3879 18.7277 25.4956C19.5089 26.5812 20.5812 27.3712 21.742 27.9786C23.686 28.9958 26.1584 29.6304 28.5 30.2141V45.4771C26.5399 45.4091 24.7338 45.1643 23.3339 44.4126C22.5029 43.9663 21.8229 43.3427 21.3361 42.4384C20.8415 41.5196 20.5 40.2237 20.5 38.3727H17.5C17.5 40.5854 17.9082 42.3997 18.6946 43.8604C19.4887 45.3357 20.6209 46.3609 21.9146 47.0556C23.9294 48.1375 26.344 48.4138 28.5 48.4802V52H31.5V48.4438C33.764 48.2729 36.1711 47.693 38.17 46.4435C40.7066 44.8578 42.5 42.2345 42.5 38.3727C42.5 34.6878 40.8257 32.2579 38.3954 30.6353C36.4028 29.305 33.8961 28.513 31.5 27.88V14.5609C33.2192 14.7025 35.0055 15.1059 36.4432 15.9274C38.1994 16.9309 39.5 18.5785 39.5 21.5H42.5C42.5 17.4215 40.5506 14.8191 37.9315 13.3226C35.9247 12.1759 33.5735 11.698 31.5 11.5523ZM28.5 14.5437C26.7464 14.6512 24.9593 14.9783 23.5274 15.7557C22.632 16.2419 21.8965 16.893 21.3766 17.7717C20.8561 18.6513 20.5 19.8465 20.5 21.5C20.5 22.4724 20.7568 23.1791 21.1628 23.7434C21.5848 24.3298 22.2312 24.8487 23.1329 25.3205C24.5777 26.0765 26.4192 26.5914 28.5 27.1195V14.5437ZM31.5 30.9894V45.4338C33.349 45.2686 35.1618 44.786 36.5798 43.8997C38.2933 42.8285 39.5 41.1382 39.5 38.3727C39.5 35.8025 38.4243 34.2618 36.7296 33.1303C35.3311 32.1966 33.5267 31.5557 31.5 30.9894Z'
        fill='black'
      />
    </svg>
  );
}

FreeIcon.displayName = 'FreeIcon';

export default FreeIcon;
